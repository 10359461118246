<template>
  <div>
    <!-- Page Title -->
    <div class="section section-breadcrumbs">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h1>Calendar</h1>
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <div class="container">
        <div class="col-sm-8">
          <cEvents title="Events" :sortasc="false" />
        </div>

        <div class="col-sm-4">
          <div class="row">
            <div class="col-sm-12 text-center calendar-title">
              Holidays
            </div>
          </div>
          <div class="row">
            <div class="col-sm-offset-2 col-sm-10">
              Generally Best Trash observes the following holidays and there will be no trash pickup. Normal service will resume on the following regularly scheduled pick up day.
              <ul>
                <li>New Year's Day</li>
                <li>Memorial Day</li>
                <li>Independence Day</li>
                <li>Labor Day</li>
                <li>Thanksgiving Day</li>
                <li>Christmas Day</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cEvents from "@/components/cEvents.vue";

export default {
  name: "vCalendar",
  components: { cEvents },
  props: [],
  data: () => ({}),
  methods: {},
  computed: {},
  mounted: function() {},
};
</script>
